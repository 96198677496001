import { Component, OnInit } from '@angular/core';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  faEnvelope = faEnvelope;
  faMapMarkerAlt = faMapMarkerAlt;
  faInstagram = faInstagram;

  constructor() { }

  ngOnInit(): void {
  }

}
