<div class="splash">
    <div class="fixed-wrapper">
            <h1>MATT SOLANO</h1>
    </div>
    <div class="mountain"></div>
</div>
<app-navigation cover="true"></app-navigation>
<div class="gallery">
    <div class="gallery-inner">
            <div class="photo-box" *ngFor="let photo of gallery" [ngClass]="photo.aspect">
                <img src="{{photo.path}}" alt="{{photo.name}}">
            </div>
    </div>
</div>

