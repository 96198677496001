import { Injectable } from '@angular/core';

@Injectable()
export class PhotoService {

  private gallery = [
    {name: 'molly-p', aspect: 'portrait', path: '../../assets/photos/DSC04784-Edit.jpg', index: 1},
    {name: 'molly-l', aspect: 'landscape', path: '../../assets/photos/DSC04761-Edit.jpg', index: 2},
    {name: 'couple', aspect: 'landscape', path: '../../assets/photos/DSC00623-Edit.jpg', index: 3},
    {name: 'boy', aspect: 'portrait', path: '../../assets/photos/DSC03776-Edit.jpg', index: 4},
    {name: 'woman', aspect: 'portrait', path: '../../assets/photos/DSC00206-Edit.jpg', index: 5},
    {name: 'kids', aspect: 'landscape', path: '../../assets/photos/DSC09671.jpg', index: 6},
    {name: 'baby', aspect: 'portrait', path: '../../assets/photos/DSC03364-Edit.jpg', index: 7},
    {name: 'dock', aspect: 'portrait', path: '../../assets/photos/DSC08870-Edit.jpg', index: 8},
    {name: 'leap', aspect: 'portrait', path: '../../assets/photos/DSC07542-Edit.jpg', index: 9},
    {name: 'lila', aspect: 'portrait', path: '../../assets/photos/DSC04142-Edit.jpg', index: 10},
    {name: 'nat', aspect: 'landscape', path: '../../assets/photos/natalia.jpg', index: 11},
    {name: 'gina', aspect: 'landscape', path: '../../assets/photos/DSC02997-Edit.jpg', index: 12},
    {name: 'vin', aspect: 'portrait', path: '../../assets/photos/vinny.jpg', index: 13},
    {name: 'casey', aspect: 'portrait', path: '../../assets/photos/casey.jpg', index: 14},
    {name: 'emerald', aspect: 'landscape', path: '../../assets/photos/emeraldbay.jpg', index: 15},
    {name: 'horse', aspect: 'landscape', path: '../../assets/photos/DSC00139-Edit.jpg', index: 16},
    {name: 'rabbit', aspect: 'portrait', path: '../../assets/photos/DSC01794.jpg', index: 17},
    {name: 'brook', aspect: 'portrait', path: '../../assets/photos/DSC05451.jpg', index: 18},
    {name: 'puddle', aspect: 'landscape', path: '../../assets/photos/DSC05696.jpg', index: 19},
    {name: 'hills', aspect: 'landscape', path: '../../assets/photos/DSC05373.jpg', index: 20},
    {name: 'horsewash', aspect: 'portrait', path: '../../assets/photos/DSC07138.jpg', index: 21}

  ];

  constructor() { }

  getGallery() {
    return this.gallery;
  }
}
