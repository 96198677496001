<app-navigation cover="false"></app-navigation>

<div class="page">
    <div class="rates-wrapper">
        <h2>About Me</h2>

        <div class="rates">
            <p>I'm a photographer, designer, and software engineer based in New England and would be happy to work with you.</p>
            <h4><fa-icon [icon]="faEnvelope" size="lg"></fa-icon> <bdo dir="rtl">moc.onalosttam@</bdo><bdo dir="rtl">ttam</bdo></h4>
            <h4><fa-icon [icon]="faInstagram" size="lg"></fa-icon> <a href="https://www.instagram.com/mattsolano">@mattsolano</a></h4>
            <h4><fa-icon [icon]="faMapMarkerAlt" size="lg"></fa-icon> Boston, MA</h4>
        </div>
    </div>

    <div class="selfie">
        <img src="../../../assets/photos/matt.jpg" alt="Matt Solano">
    </div>
</div>


