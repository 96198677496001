<div class="navigation">
    <fa-icon [icon]="faBars" size="2x" (click)="toggleNav()"></fa-icon>
    <div class="nav-items" [ngClass]="{open: navOpen}">
        <div class="item" (click)="navOpen = false; goToHome()"><a>Home</a></div>
        <div class="item" (click)="navOpen = false; goToRates()"><a>Rates</a></div>
        <div class="item" (click)="navOpen = false; goToContact()"><a>Contact</a></div>
        <div class="item"><a href="https://gallery.mattsolano.com">Gallery</a></div>
    </div>
</div>
<div class="matt-cover" *ngIf="cover === 'true'"></div>
<div class="matt" (click)="goToHome()">MATT SOLANO</div>
