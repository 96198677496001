import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() cover: string;

  faBars = faBars;
  navOpen = false;

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  toggleNav() {
    this.navOpen = !this.navOpen;
  }

  goToHome() {
    this.router.navigate(['home']);
  }

  goToRates() {
    this.router.navigate(['rates']);
  }

  goToContact() {
    this.router.navigate(['contact']);
  }

}
