<app-navigation cover="false"></app-navigation>

<div class="page">
    <div class="rates-wrapper">
        <h2>Rates</h2>

        <div class="rates">
            <p>Below are my basic rates for photo sessions. Please feel free to reach out if you'd like more info.</p>
            <h4>Solo</h4>
            <p><strong>$200</strong> / <span>1 hour session</span></p>

            <h4>Couples/Groups</h4>
            <p><strong>$300</strong> / <span>1 hour session</span></p>

            <p><em>All sessions include</em></p>
            <ul>
                <li>10+ full-resolution, retouched photos</li>
                <li>Online albums containing your proofs and finals</li>
                <li>Additional photos can be purchased for $100/per set of 10</li>
            </ul>

        </div>
    </div>
</div>

