import { Component, OnInit } from '@angular/core';

import { PhotoService } from '../../services/photo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  gallery = [];

  constructor(
      private photoService: PhotoService
  ) {}

  ngOnInit(): void {
    this.gallery = this.photoService.getGallery();
  }

}
